import { useEffect, useState } from 'react';
import '../styles/pages/report.css';
import 'leaflet/dist/leaflet.css';
import api from '../services/api';
import Sidebar from '../components/Sidebar';
import * as CONFIG from '../config/constants';
import ReactPaginate from 'react-paginate';
import { AxiosResponse } from 'axios';

interface Contact {
  name: string;
  email: string;
  message: string;
  latitude: number;
  longitude: number;
  city: string;
  state: string;
  videos: Video[];
}

interface Video {
  id: number;
  path: string;
}

interface SelectedItem {
  selected: number;
}

function Report() {

  const [contacts, setContacts] = useState<Contact[]>([]);
  const [pageCount, setPageCount] = useState(0);
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  let limit = 10;

  useEffect(() =>{
    try{
      // api.get('contact').then(response => {
      //   console.log(response.data);
      //   setContacts(response.data);
      // });

      const getContacts = async () => {
        api.get('contact', { params: {page: 1 , limit} }).then(response => {
          const total = response.headers["x-total-count"];
          setPageCount(Math.ceil(total / limit));
          setContacts(response.data);
        });
      };
  
      getContacts();

    }
    catch(err) {
      alert("Não foi possível buscar os dados. Tente novamente mais tarde");
    }
  }, [limit]);

  const fetchContacts = async (page: number): Promise<Contact[]> => {
    const response: AxiosResponse<Contact[]> = await api.get('contact', { params: {page , limit} });
      return response.data;    
  };


  function handlerFilter(){

    try{ 
      api.get('contact').then(response => {
        let contactsFiltered: Contact[] = response.data;
  
        if(name)
          contactsFiltered = contactsFiltered.filter(contact => contact.name && contact.name.trim().toLowerCase().includes(name.trim().toLowerCase()));
  
        if(city)
          contactsFiltered = contactsFiltered.filter(contact => contact.city && contact.city.trim().toLowerCase().includes(city.trim().toLowerCase()));
  
        if(state)
          contactsFiltered = contactsFiltered.filter(contact => contact.state && contact.state.trim().toLowerCase().includes(state.trim().toLowerCase()));
  
        setContacts(contactsFiltered);
      });    
    }
    catch(err) {
      alert("Não foi possível buscar os dados. Tente novamente mais tarde");
    }    
  }

  function handlerClear(){

    setName('');
    setCity('');
    setState('');

    api.get('contact').then(response => {
      setContacts(response.data);
    });    
    
  }

  const handlePageClick = async (data: SelectedItem) => {
    let currentPage = data.selected + 1;

    let contactsFormServer: Contact[];
    contactsFormServer = await fetchContacts(currentPage);

    setContacts(contactsFormServer);
    // scroll to the top
    //window.scrollTo(0, 0)
  };

  return (
      <div id="page-report">
          <Sidebar />

          <main className="mainReport">
            <div className="divTitle">
              <h1>Relatório de Acessos</h1>
            </div>
            <div className="divFilters">
              <div>
                <input type="text" placeholder="Nome" maxLength={50} value={name} onChange={event => setName(event.target.value)}></input>
              </div>
              <div>
                <input type="text" placeholder="Cidade" maxLength={50} value={city} onChange={event => setCity(event.target.value)}></input>
              </div>
              <div>
                <input type="text" placeholder="Estado" maxLength={30} value={state} onChange={event => setState(event.target.value)}></input>
              </div>
              <div>
                <button type="button" onClick={() => handlerFilter()}>Filtrar</button>
              </div>
              <div>
                <button type="button" onClick={() => handlerClear()}>Limpar</button>
              </div>
            </div>
            <table className="content-table">
              <thead>
                <tr>
                  <td>
                    Nome
                  </td>
                  <td>
                    E-mail
                  </td>
                  <td>
                    Mensagem
                  </td>
                  <td>
                    Cidade
                  </td>
                  <td>
                    Estado
                  </td>
                  <td>
                    Lat./Long.
                  </td>
                  <td>
                    Vídeo Enviado
                  </td>
                </tr>
              </thead>
              <tbody>
                {contacts.map((contact, index) => {
                  return (
                    <tr key={index}>
                      <td>{ contact.name }</td>
                      <td>{ contact.email }</td>
                      <td>{ contact.message }</td>
                      <td>{ contact.city }</td>
                      <td>{ contact.state }</td>
                      <td>
                        <a href={`https://www.google.com/maps/?q=${contact.latitude},${contact.longitude}`} target="_blank" rel="noreferrer">{ contact.latitude } / { contact.longitude }</a>
                      </td>
                      <td>
                        <a href={`${CONFIG.BACKEND_URL}/uploads/${contact.videos[0]?.path}`} target="_blank" rel="noreferrer">{ contact.videos[0]?.path }</a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="<"
              containerClassName="pagination justify-content-center"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              activeClassName="active"
            />
          </main>
    </div>
  );
}

export default Report;